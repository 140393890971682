<template>
	<div>
		<div class="header">
			<span>조세형사</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>“탈세 형량이 궁금합니다..“</span><br/>
				<span>“허위세금계산서 발급으로 조사가 나왔는데 어떻게 대응해야 하나요?”</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>조세형사(조세포탈)의 핵심?</span>
				</div>

				<div class="text">
					<span>조세형사(조세포탈)의 핵심은 거래관계 구성입니다. 조세형사사건은 일반형사와는 달리 국세청에 전속적 고발건이 있기 때문에 세법에 부합하도록 거래관계를 재조명할 수 있어야 합니다. 또한, 조세형사사건은 조세행정소송과도 맞물려 진행될 수 밖에 없으므로 조세전문변호사와의 대응은 선택이 아닌 필수입니다.</span>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>조세형사(조세포탈)에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.</span>
				</div>

				<div class="question">
					<div class="q">
						<span>Q1. 허위세금계산서 발급, 어떤 처벌을 받게 되나요?</span>
					</div>
					<div class="a">
						<span>재화 또는 용역을 공급하거나 받지 않고, 세금계산서를 발급하거나 받는 행위는 조세범처벌법 위반에 해당합니다.</span><br/>
						<span>이는 3년 이하의 징역 또는 공급가액에 부가가치세율을 적용해 계산 세액의 3배 이하의 상당하는 벌금에 처해질 수 있습니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q2. 업무상 횡령, 형량이 궁금합니다.</span>
					</div>
					<div class="a">
						<span>횡령 혐의의 경우 결국 경제범죄이기 때문에 횡령한 금액에 따라 처벌수위가 달라지게 됩니다.</span><br/>
						<v-img src="~@/assets/table.png"/>
						<span>특히 업무상 횡령 혐의는 자신이 근무하는 회사의 자금에 접근이 가능한 지위를 이용해 범죄 행위를 저지른 것이기 때문에 보통 횡령죄보다 당연히 처벌수위가 높아집니다.</span><br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
	
</style>

<script>
	export default {
		name: 'WorkPart3',
	}
</script>