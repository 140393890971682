<template>
	<div class="main">
		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>허진영 변호사의 성공사례</span>
						</div>
						<div class="title2">
							<span>긴말하지 않겠습니다.</span><br/>
							<span>승소사례로 확인하세요.</span><br/>
						</div>
						<div class="cases">
							<div v-for="item in cases" :key="item.id">
								<div class="cover"><span>성공사례</span></div>
								<v-img :src="require('@/assets/cases/' + item)"/>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			padding: 30px 0;
			background: #F3F3F3;
		}
		.title1 {
			color: #000;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			display: grid;
			grid-template-columns: calc(50% - 10px) 50%;
			grid-template-rows: auto auto;
			row-gap: 10px;
			column-gap: 10px;
		}
		.cases > div {
			position: relative;
			border-radius: 5px;
			background: #bacedf;
		}
		@font-face {
			font-family: 'TTWanjunuricheR';
			src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/TTWanjunuricheR.woff2') format('woff2');
			font-weight: normal;
			font-style: normal;
		}
		.cases > div:hover > div.cover {
			opacity: 0.1;
		}
		.cases > div > div.cover {
			position: absolute;
			top: 0; left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid #FFF;
			border-radius: 5px;
			box-sizing: border-box;
			background: rgba(0,0,0,0.4);
			transition: opacity 0.5s;
			cursor: pointer;
			z-index: 1;
		}
		.cases > div > div.cover > span {
			position: absolute;
			top: 50%; left: 50%;
			width: 100px;
			content: '성공사례';
			color: #FFF;
			font-size: 24px;
			font-family: 'TTWanjunuricheR';
			text-align: center;
			border: 5px solid #FFF;
			border-radius: 5px;
			transform: translate(-50%, -50%) rotate(-30deg);
			z-index: 2;
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			padding: 100px 0;
			background: #F3F3F3;
		}
		.title1 {
			margin-bottom: 10px;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 30px;
			color: #333;
			font-size: 20px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			display: grid;
			grid-template-columns: 33% 33% 33%;
			grid-template-rows: auto auto;
			row-gap: 20px;
			column-gap: 20px;
		}
		.cases > div {
			position: relative;
			border-radius: 5px;
			background: #bacedf;
		}
		@font-face {
			font-family: 'TTWanjunuricheR';
			src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2307-1@1.1/TTWanjunuricheR.woff2') format('woff2');
			font-weight: normal;
			font-style: normal;
		}
		.cases > div:hover > div.cover {
			opacity: 0.1;
		}
		.cases > div > div.cover {
			position: absolute;
			top: 0; left: 0;
			width: 100%;
			height: 100%;
			border: 2px solid #FFF;
			border-radius: 5px;
			box-sizing: border-box;
			background: rgba(0,0,0,0.4);
			transition: opacity 0.5s;
			cursor: pointer;
			z-index: 1;
		}
		.cases > div > div.cover > span {
			position: absolute;
			top: 50%; left: 50%;
			width: 300px;
			content: '성공사례';
			color: #FFF;
			font-size: 64px;
			font-family: 'TTWanjunuricheR';
			text-align: center;
			border: 5px solid #FFF;
			border-radius: 5px;
			transform: translate(-50%, -50%) rotate(-30deg);
			z-index: 2;
		}
	}
</style>

<script>
	export default {
		name: 'SuccessCases',
		data() {
			return {
				cases: [
					'case1.png',
					'case2.png',
					'case3.png',
					'case4.png',
					'case5.png',
					'case6.png',
					'case7.png',
					'case8.png',
					'case9.png',
				],
			}
		},
	}
</script>
