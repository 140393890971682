<template>
	<div class="main">
		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>법무법인 윤성 의뢰인의 진짜 후기</span>
						</div>
						<div class="title2">
							<span>감사하게도 많은 의뢰인분들이 저의 철학을 알아봐주십니다.</span>
						</div>
						<div class="cases">
							<div v-for="review in reviews" :key="review.id">
								<v-img :src="require('@/assets/reviews/' + review)"/>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			padding: 30px 0;
			background-size: cover;
		}
		.title1 {
			color: #000;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 14px;
			word-break: keep-all;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: auto auto;
			row-gap: 10px;
			column-gap: 10px;
		}
		.cases > div {
			border-radius: 5px;
			background: #bacedf;
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			padding: 100px 0;
			min-height: 1250px;
			background: #F3F3F3;
		}
		.title1 {
			margin-bottom: 10px;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 30px;
			color: #333;
			font-size: 20px;
		}
		.contents {
			max-width: 1200px;
			height: 400px;
			margin: auto;
		}
		.cases {
			display: grid;
			grid-template-columns: 33% 33% 33%;
			grid-template-rows: auto auto;
			row-gap: 20px;
			column-gap: 20px;
		}
		.cases > div {
			border-radius: 5px;
			background: #bacedf;
		}
	}
</style>

<script>
	export default {
		name: 'RealReview',
		data() {
			return {
				reviews: [
					'review1.jpg',
					'review2.jpg',
					'review3.jpg',
					'review4.jpg',
					'review5.jpg',
					'review6.jpg',
				],
			}
		},
	}
</script>
