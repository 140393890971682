<template>
	<div>
		<div class="header">
			<span>상속/증여</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>“부모님은 제가 모셨는데, 재산은 형이 다 상속을 받았습니다.“</span><br/>
				<span>“부모님이 남기고 가신 빚은 어떻게 해결해야 하나요?”</span><br/>
				<span>“전세자금으로 받은 3억 원, 증여세 내야 하나요?“</span><br/>
				<span>“부동산 증여 반환 후 먼저 낸 증여세 돌려받을 수 있을까요?”</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>상속/증여의 핵심?</span>
				</div>

				<div class="text">
					<span>상속/증여 문제는 확인하고 준비해야 할 사항들이 무수히 많기 때문에, 개인이 이를 놓치지 않고 모두 파악하고 정리하기란 사실상 불가능에 가깝습니다.</span><br/>
					<span>특히 가족 간 상속/증여의 첫 단추를 잘못 끼우면 가족 모두의 심적 고통이 커질 수 밖에 없기 때문에, 갈등을 최소화할 수 있는 슬기로운 전략이 필요합니다.</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>상속/증여에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.</span>
				</div>

				<div class="question">
					<div class="q">
						<span>Q1. 상속세 세무조사가 나왔습니다.</span>
					</div>
					<div class="a">
						<span>상속세는 상속세 신고를 하였다고 절차가 모두 끝나는 것이 아니라, 과세 관청이 세액을 결정해야 끝나기 때문에 마지막까지 각별한 주의가 필요합니다.</span><br/>
						<span>고액 상속일 경우 별도의 세무조사를 받을 수 있고, 세무조사 과정에서 납세자조차 생각하지 못했던 거래 등이 발견될 수 있기 때문에 상속세 신고부터 마지막 납부까지 긴장의 끈을 놓아서는 안됩니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q2. 상속채무가 있다는 사실을 모른 채 상속 재산을 써버렸는데, 지금이라도 상속포기가 가능할까요?</span>
					</div>
					<div class="a">
						<span>상속인이 상속 재산을 사용한 경우, 상속재산의 승계를 무조건적으로 수락한 것으로 보기 때문에 상속인은 한정승인이나 상속포기를 할 수 없습니다.</span><br/>
						<span>다만, 상속 채무가 상속 재산을 초과하는 사실을 ****중대한 과실 없이 3개월의 기간 내에 알지 못한 경우에는 그 사실을 안 날로부터 3개월 내에 한정 승인을 할 수 있습니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q3. 상속포기를 하였는데 상속세 신고를 해야 하나요?</span>
					</div>
					<div class="a">
						<span>상속인은 상속을 포기하더라도 상속세 및 증여세법상 상속세 납세의무가 있습니다.</span><br/>
						<span>따라서 상속포기 절차를 진행할 때, 상속개시일 현재 피상속인 명의의 재산이 없더라도 세법에서 정한 상속세 부과 대상 재산이 있는지 검토해야 합니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q4. 상속포기 시 주의할 점이 있나요?</span>
					</div>
					<div class="a">
						<span>선순위 상속인이 상속을 포기한다고 해서 상속관계가 한번에 정리되는 것이 아니기 때문에 피상속인의 채무를 완전히 면하기 위해서는 순위 여하를 불문하고 모든 상속인들이 상속 포기를 해야 합니다.</span><br/>
						<span>민법에서는 이러한 사실을 알지 못한 채 상속을 하는 경우, 상속인의 중대한 과실로 보기 때문에 상속 시 꼭 주의하시길 바랍니다.</span><br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
</style>

<script>
	export default {
		name: 'WorkPart6',
	}
</script>