<template>
	<div class="main">
		<v-dialog
			v-model="dialog"
			width="800"
		>
			<div class="dialog">
				<div class="body">
					<Part1Vue v-if="item == 0"/>
					<Part2Vue v-if="item == 1"/>
					<Part3Vue v-if="item == 2"/>
					<Part4Vue v-if="item == 3"/>
					<Part5Vue v-if="item == 4"/>
					<Part6Vue v-if="item == 5"/>
					<Part7Vue v-if="item == 6"/>
					<Part8Vue v-if="item == 7"/>
				</div>
			</div>
		</v-dialog>

		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>법무법인 윤성 업무 분야</span>
						</div>
						<div class="title2">
							<span>꼼꼼함을 넘어 깐깐하게 11년 차 허진영 변호사가 직접 처리합니다.</span>
						</div>
						<div class="cases">
							<div @click="showWorkPart(0)">
								<span>세무조사</span>
							</div>
							<div @click="showWorkPart(1)">
								<span>조세불복</span>
							</div>
							<div @click="showWorkPart(2)">
								<span>조세형사</span>
							</div>
							<div @click="showWorkPart(3)">
								<span>조세소송</span>
							</div>
						</div>
						<div class="cases">
							<div @click="showWorkPart(4)">
								<span>민사/기업/형사/부동산</span>
							</div>
							<div @click="showWorkPart(5)">
								<span>상속/증여</span>
							</div>
							<div @click="showWorkPart(6)">
								<span>세금컨설팅</span>
							</div>
							<div @click="showWorkPart(7)">
								<span>계약서/약관검토 및 작성</span>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			padding: 30px 0;
		}
		.title1 {
			color: #000;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			width: 100%;
			gap: 15px;
		}
		.cases > div {
			display: inline-block;
			width: calc(50% - 10px);
			padding: 15px 0;
			margin: 5px;
			font-size: 14px;
			font-weight: 700;
			text-align: center;
			border: 1px solid #D9D9D9;
			border-radius: 5px;
			transition: border .3s, color .3s, background-color .3s;
			cursor: pointer;
		}
		.cases > div:hover {
			color: #E38787;
			border: 1px solid #E38787;
			background-color: rgba(227,135,135,0.05);
		}

		.dialog {
			padding: 20px;
			background: #FFF;
		}
	}

	@media (min-width: 768px) {
		.main {
			padding: 100px 0;
		}
		.title1 {
			color: #000;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 20px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			width: 100%;
			gap: 15px;
		}
		.cases > div {
			display: inline-block;
			width: calc(25% - 10px);
			padding: 15px 0;
			margin: 5px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
			border: 1px solid #D9D9D9;
			border-radius: 5px;
			transition: border .3s, color .3s, background-color .3s;
			cursor: pointer;
		}
		.cases > div:hover {
			color: #E38787;
			border: 1px solid #E38787;
			background-color: rgba(227,135,135,0.05);
		}

		.dialog {
			padding: 20px;
			background: #FFF;
		}
	}
</style>

<script>
	import Part1Vue from './workparts/Part1.vue';
	import Part2Vue from './workparts/Part2.vue';
	import Part3Vue from './workparts/Part3.vue';
	import Part4Vue from './workparts/Part4.vue';
	import Part5Vue from './workparts/Part5.vue';
	import Part6Vue from './workparts/Part6.vue';
	import Part7Vue from './workparts/Part7.vue';
	import Part8Vue from './workparts/Part8.vue';

	export default {
		name: 'WorkPart',
		components: {
			Part1Vue,
			Part2Vue,
			Part3Vue,
			Part4Vue,
			Part5Vue,
			Part6Vue,
			Part7Vue,
			Part8Vue,
		},
		data() {
			return {
				dialog: false,
				item: 0,
			}
		},
		methods: {
			showWorkPart(index) {
				var vm = this;
				vm.dialog = true;
				vm.item = index;
			}
		}
	}
</script>
