<template>
	<div class="main">
		<v-dialog
			v-model="dialog"
			width="500"
		>
			<div class="dialog">
				<div class="header">
					<span>문의하기</span>
				</div>

				<div class="body">
					<div>
						<v-text-field
							v-model="name"
							label="성함"
							:outlined="true"
							:hide-details="true"
						/>
					</div>
					<div>
						<v-text-field
							v-model="phone"
							label="연락처"
							:outlined="true"
							:hide-details="true"
						/>
					</div>
					<div>
						<v-text-field
							v-model="email"
							label="이메일"
							:outlined="true"
							:hide-details="true"
						/>
					</div>
					<div>
						<v-autocomplete
							v-model="workPart"
							:items="['세무조사', '조세불복', '조세형사', '조세소송', '민사/기업/형사/부동산', '상속/증여', '세금컨설팅', '계약서/약관검토 및 작성']"
							:outlined="true"
							:hide-details="true"
							:chips="true"
							label="상담분야"
						/>
					</div>
					<div>
						<v-textarea
							v-model="counsel"
							label="변호사에게 상담 받으신 경험"
							:outlined="true"
							:hide-details="true"
						/>
					</div>
					<div>
						<v-textarea
							v-model="content"
							label="상담내용"
							:outlined="true"
							:hide-details="true"
						/>
					</div>
					<div>
						<v-checkbox
							v-model="checkbox"
							:label="`개인정보 수집 및 이용 동의`"
						/>
					</div>
					<div>
						<v-btn
							x-large
							width="100%"
							elevation="0"
							color="#E38787"
							class="white--text"
							:loading="isSending"
							:disabled="isSending"
							@click="submitInquiry"
						>
							문의하기
						</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>

		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>상담시간 및 위치안내</span>
						</div>
						<div class="title2">
							<span>의뢰인과의 약속이 최우선 입니다.</span>
						</div>
						<div class="cases">
							<div>
								<div>
									<span>법무법인 윤성</span>
								</div>
								<div>
									<span>법무법인 윤성과 함께 한다는 것은</span><br/>
									<span>스스로를 지키는 힘을 갖게 되는 것과 같습니다.</span><br/>
								</div>
								<div>
									<div>
										<div>
											<span>월/화/수/목/금</span>
										</div>
										<div>
											<span>09:00 ~ 18:00</span>
										</div>
									</div>

									<div>
										<div>
											<span>점심시간</span>
										</div>
										<div>
											<span>11:30 ~ 12:30</span>
										</div>
									</div>

									<div>
										<div>
											<span>토/일</span>
										</div>
										<div>
											<span>카톡채널 문의</span>
										</div>
									</div>
								</div>
								<div>
									<a href="tel:02-889-8080">
										<span>tel: 02-889-8080</span>
									</a>
								</div>
							</div>
							<div>
								<div id="map"></div>
							</div>
						</div>
						<div class="contact">
							<span><span class="highlight">사전상담이 먼저 진행</span>되며, 그 이후</span><br/>
							<span>저희 법률서비스가 정말 필요하다고 판단되는 경우에만 <span class="highlight">결제를 요청</span>합니다.</span><br/>
							<span>문의를 남겨주시면 도움을 드릴 수 있는지 꼼꼼히 확인 후, <span class="highlight">순차적으로 상담을 도와드리겠습니다.</span></span><br/>
						</div>
						<div class="button">
							<v-btn
								x-large
								width="200px"
								elevation="0"
								color="#E38787"
								class="white--text"
								@click="showInquiry"
							>
								문의하기
							</v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			padding: 30px 0;
			background: #F3F3F3;
		}
		.title1 {
			color: #000;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.highlight {
			color: #E38787;
			font-weight: 700;
		}
		.cases {
			width: 100%;
			margin-bottom: 30px;
		}
		.cases > div:nth-child(1) {
			width: 100%;
			height: 300px;
			padding: 25px;
			margin-bottom: 10px;
			background-size: cover;
			background-image: url('../assets/time.jpg');
		}
		.cases > div:nth-child(1) > div:nth-child(1) {
			margin-bottom: 10px;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(2) {
			margin-bottom: 30px;
			color: #FFF;
			font-size: 14px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) {
			margin-bottom: 100px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			margin-bottom: 10px;
			color: #FFF;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(1) {
			width: 150px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(2) {
			
		}
		.cases > div:nth-child(1) > div:nth-child(4) {
			color: #FFF;
			font-size: 32px;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(4) > a {
			color: #FFF !important;
			text-decoration: none;
			cursor: pointer;
		}
		.cases > div:nth-child(1) > div:nth-child(4) > a:hover {
			text-decoration: underline;
		}
		.cases > div:nth-child(2) {
			width: 100%;
			height: 300px;
			border: 1px solid #A9A9A9;
		}
		.contact {
			margin-bottom: 30px;
			font-size: 14px;
			text-align: center;
			line-height: 20px;
			word-break: keep-all;
		}
		.button {
			text-align: center;
		}
		#map {
			position: relative;
			width: 100%;
			height: 300px;
		}
		::v-deep {
			.marker-label {
				position: relative;
				padding: 3px 15px;
				color: #FFF;
				font-size: 13px;
				font-weight: 700;
				border-radius: 5px;
				background: #7c4949;
			}
			.marker-label::after {
				position: absolute;
				content: '';
				left: 0; right: 0; bottom: -10px;
				margin: auto;
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid #7c4949;
			}
			.marker-label > a {
				color: #FFF;
				text-decoration: none;
			}
			.marker-label > a:hover {
				text-decoration: underline;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			padding: 100px 0;
		}
		.title1 {
			margin-bottom: 10px;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 30px;
			color: #333;
			font-size: 20px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.highlight {
			color: #E38787;
			font-weight: 700;
		}
		.cases {
			display: flex;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 30px;
		}
		.cases > div:nth-child(1) {
			width: 400px;
			height: 400px;
			padding: 25px;
			background-size: cover;
			background-image: url('../assets/time.jpg');
		}
		.cases > div:nth-child(1) > div:nth-child(1) {
			margin-bottom: 10px;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(2) {
			margin-bottom: 30px;
			color: #FFF;
			font-size: 14px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) {
			margin-bottom: 100px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div {
			display: flex;
			justify-content: flex-start;
			width: 100%;
			margin-bottom: 10px;
			color: #FFF;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(1) {
			width: 150px;
		}
		.cases > div:nth-child(1) > div:nth-child(3) > div > div:nth-child(2) {
			
		}
		.cases > div:nth-child(1) > div:nth-child(4) {
			color: #FFF;
			font-size: 32px;
			font-weight: 700;
		}
		.cases > div:nth-child(1) > div:nth-child(4) > a {
			color: #FFF !important;
			text-decoration: none;
			cursor: pointer;
		}
		.cases > div:nth-child(1) > div:nth-child(4) > a:hover {
			text-decoration: underline;
		}
		.cases > div:nth-child(2) {
			width: 800px;
			height: 400px;
		}
		.contact {
			margin-bottom: 30px;
			text-align: center;
			line-height: 28px;
		}
		.button {
			text-align: center;
		}
		#map {
			position: relative;
			width: 800px;
			height: 400px;
		}
		::v-deep {
			.marker-label {
				position: relative;
				padding: 3px 15px;
				color: #FFF;
				font-size: 13px;
				font-weight: 700;
				border-radius: 5px;
				background: #7c4949;
			}
			.marker-label::after {
				position: absolute;
				content: '';
				left: 0; right: 0; bottom: -10px;
				margin: auto;
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid #7c4949;
			}
			.marker-label > a {
				color: #FFF;
				text-decoration: none;
			}
			.marker-label > a:hover {
				text-decoration: underline;
			}
		}
	}

	.dialog {
		padding: 20px;
		background: #FFF;

		.header {
			padding: 10px;
			margin-bottom: 20px;
			font-size: 24px;
			font-weight: 600;
			text-align: center;
		}
		.body {
			
		}
		.body > div {
			margin-bottom: 20px;
		}
	}
</style>

<script>
	import axios from 'axios';

	export default {
		name: 'TimeLocation',
		data() {
			return {
				dialog: false,
				lat: 37.503326583073964,
				lng: 127.04988038878253,
				map: null,
				isSending: false,
				checkbox: false,
				textInquiry: '',
				name: '',
				phone: '',
				email: '',
				workPart: '',
				counsel: '',
				content: '',
			}
		},
		mounted() {
			var vm = this;
			if(window.kakao && window.kakao.maps) {
				vm.loadMap();
			} else {
				vm.loadScript();
			}
		},
		methods: {
			showInquiry() {
				var vm = this;
				vm.dialog = true;
			},
			submitInquiry() {
				var vm = this;
				if(vm.name.length < 2) {
					alert('이름을 입력해주세요.');
					return;
				}

				if(vm.phone.length < 11) {
					alert('전화번호를 정확하게 입력해주세요.');
					return;
				}

				if(vm.email.length < 5 || !vm.email.includes('@')) {
					alert('이메일 주소를 정확하게 입력해주세요.');
					return;
				}

				if(!vm.checkbox) {
					alert('개인정보 수집 및 이용에 동의해주세요.');
					return;
				}

				vm.isSending = true;
				vm.textInquiry = "성함: " + vm.name + "\n"
				vm.textInquiry = vm.textInquiry + "연락처: " + vm.phone + "\n";
				vm.textInquiry = vm.textInquiry + "이메일: " + vm.email + "\n";
				vm.textInquiry = vm.textInquiry + "상담분야: " + vm.workPart + "\n";
				vm.textInquiry = vm.textInquiry + "상담경험: " + vm.counsel + "\n";
				vm.textInquiry = vm.textInquiry + "상담내용: " + vm.content + "\n";

				setTimeout(function() {
					axios({
						method: "POST",
						data: {
							plainText: vm.textInquiry,
						},
						url: "https://tb8951zxu1.execute-api.ap-northeast-2.amazonaws.com/production",
					}).then((res) => {
						vm.isSending = false;
						console.log(res);
					}).catch((res) => {
						vm.isSending = false;
						console.log(res);
					});
				}, 1000);
			},
			loadScript() {
				var vm = this;
				const script = document.createElement('script');
				script.src = "//dapi.kakao.com/v2/maps/sdk.js?appkey=9548fac9d20abe5ed496fa507496faa2&autoload=false";
				script.onload = () => window.kakao.maps.load(vm.loadMap);
				document.head.appendChild(script);
			},
			loadMap() {
				var vm = this;
				const container = document.getElementById('map');
				const options = {
					center: new window.kakao.maps.LatLng(vm.lat, vm.lng),
					level: 3,
				};
				vm.map = new window.kakao.maps.Map(container, options);
				vm.loadCustomOverlay();
			},
			loadCustomOverlay() {
				var vm = this;
				const content = '<div class="marker-label"><a target="_blank" href="http://kko.to/PWuaYq24ec"><span class="left"></span><span class="center">법무법인 윤성</span></a></div>';
				const position = new window.kakao.maps.LatLng(vm.lat + 0.00023, vm.lng);
				const customOverlay = new window.kakao.maps.CustomOverlay({
					position: position,
					content: content,
				});
				customOverlay.setMap(vm.map);
			}
		}
	}
</script>
