<template>
	<div>
		<div class="header">
			<span>세무조사</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>"이번에 세무조사가 나온다고 하네요.. 처음에는 어떤걸 준비해야 하나요?"</span><br/>
				<span>"저는 세금도 철저하게 냈는데 세무조사가 나와 너무 억울합니다.."</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>세무조사의 핵심?</span>
				</div>

				<div class="text">
					<span>대부분 세무조사는 세무대리인인 세무사 · 회계사와 세무공무원 간 <span style="color: #E38787; font-weight: 700">세법해석</span>이 달라 촉발되기 때문에 조사 결과를 납세자가 납득하기 어려울 경우 법률전문가인 변호사의 대응이 필요합니다.</span>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>변호사의 세무조사 대응, 이런 분들이 필요합니다.</span>
				</div>

				<div class="text">
					<span>· 세무조사통지를 받고 막막하신 분</span><br/>
					<span>· 기존 세무사/회계사님과 세무조사 대응 소통이 어려우신 분</span><br/>
					<span>· 첫 단추인 세무조사 문답서 작성부터 변호사의 조력을 받고 싶으신 분</span><br/>
					<span>· 세무조사 도중 형사사건으로 전환될 가능성이 있는 분</span><br/>
					<span>· 추후 행정소송이나 형사소송을 미리 대비하고 싶으신 분</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>세무조사에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.</span>
				</div>

				<div class="question">
					<div class="q">
						<span>Q1. 세무조사 문답서는 어떻게 작성해야 하나요?</span>
					</div>
					<div class="a">
						<span>세무조사 문답서는 보통 세무조사가 촉발된 쟁점 사실에 대한 발생 원인, 경위, 결과 등을 조사요원이 질문하고 납세자가 답변하는 문답 형식으로 작성하게 됩니다.</span><br/>
						<span>문답서는 작성이 완료되면 쉽게 번복할 수 없기 때문에 처음 심문받을 때부터 주의를 기울여야 합니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q2. 세무조사가 조세범칙조사로 전환될 수 있나요?</span>
					</div>
					<div class="a">
						<span>세무조사 과정에서 조세포탈, 허위세금계산서 등 명백한 조세범죄혐의가 발견되면 세무조사와는 별개로 조세포탈, 허위세금계산서에 대한 범칙조사가 추가로 진행됩니다.</span><br/>
						<span>조세범칙으로 전환되면 세무공무원은 경찰과 같은 위치에서 조사를 하게 되고, 세무서 조사실에서의 진술은 이후 형사재판에서도 그대로 이어지게 되므로, 문답서나 조서 작성 시 신중하게 대응하셔야 합니다.</span><br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
	
</style>

<script>
	export default {
		name: 'WorkPart1',
	}
</script>