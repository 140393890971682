<template>
	<div class="main">
		<v-container class="">
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="words">
						<div class="title1">
							<span>반갑습니다.</span><br/>
							<span>법무법인 윤성 허진영 변호사입니다.</span><br/>
						</div>
						<div class="title2">
							<span>저와 5년 동안 17건의 소송을 진행하셨던 대표님이 저에게 말씀하시더군요.</span><br/>
							<span style="font-weight: 700;">"변호사님, 저는 결과가 어떻게 나오든 변호사님 믿고 끝까지 갈 겁니다"</span><br/>
							<span style="font-weight: 700;">“저를 믿고 기다리는 직원들 때문이라도 회사를 꼭 지키고 싶습니다.”</span><br/>
							<br/>
							<span>저를 찾아오신 분들은 소중한 사람들을 보호하기 위해 변호사를 선임하셨습니다.</span><br/>
							<span>제가 처음 변호사를 하기로 마음먹은 것도 바로 그 이유 때문이었습니다.</span><br/>
							<br/>
							<span>11년간 그래왔듯, 저의 모든 경험과 노하우가 전달될 수 있도록 변호사로서 책임감을 갖고</span><br/>
							<span>업에 충실히 임하겠습니다. 감사합니다.</span><br/>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			padding: 30px 0;
			background-size: cover;
		}
		.title1 {
			margin-bottom: 30px;
			color: #FFF;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			color: #000;
			font-size: 14px;
			line-height: 24px;
			word-break: keep-all;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.container {
			position: relative;
			padding-top: 100px;
			background-size: contain;
			background-image: url('../assets/lawyer.png');
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			max-width: 1200px;
			margin: auto;
			padding-top: 120px;
			padding-bottom: 40px;
		}
		.container {
			position: relative;
			width: 1200px;
			height: 620px;
			background-size: cover;
			background-image: url('../assets/lawyer.png');
		}
		.title1 {
			position: absolute;
			top: 10%;
			left: 0;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			position: absolute;
			top: 42%;
			left: 2%;
			color: #FFF;
			font-size: 16px;
			line-height: 27px;
		}
	}
</style>

<script>
	export default {
		name: 'LawyerIntro',
	}
</script>
