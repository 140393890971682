<template>
  <v-app>
    <v-main>
      <HeadLine/>
			<LawyerIntro/>
			<SuccessCases/>
			<HowResult/>
			<RealReview/>
			<WorkPart/>
			<YsPromise/>
			<TimeLocation/>
			<FootLine/>
    </v-main>
  </v-app>
</template>

<script>
import HeadLine from './components/HeadLine';
import LawyerIntro from './components/LawyerIntro';
import SuccessCases from './components/SuccessCases';
import HowResult from './components/HowResult';
import RealReview from './components/RealReview';
import WorkPart from './components/WorkPart';
import YsPromise from './components/YsPromise';
import TimeLocation from './components/TimeLocation';
import FootLine from './components/FootLine';

export default {
  name: 'App',
  components: {
    HeadLine,
		LawyerIntro,
		SuccessCases,
		HowResult,
		RealReview,
		WorkPart,
		YsPromise,
		TimeLocation,
		FootLine,
  },
};
</script>
