<template>
	<div>
		<div class="header">
			<span>조세소송(행정소송)</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>“조세불복 후 행정소송 진행 절차가 궁금합니다.“</span><br/>
				<span>“심판청구에 대한 기각결정문을 받았습니다.. 행정소송을 하면 승산이 있을까요?”</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>조세소송(행정소송)의 핵심?</span>
				</div>

				<div class="text">
					<span>조세소송(행정소송)은 행정심판 전치주의에 따라 과세전 적부 심사청구, 이의신청, 심사청구, 심판청구 등 조세 불복 과정을 먼저 거친 후 진행이 가능합니다.</span><br/>
					<span>조세소송(행정소송)은 심사(심판)청구에 대한 결정통지를 받은 날로부터 90일 이내 제기할 수 있습니다.</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>조세소송(행정소송)에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.</span>
				</div>

				<div class="question">
					<div class="q">
						<span>Q1. 조세소송의 의의와 종류가 궁금합니다.</span>
					</div>
					<div class="a">
						<span>조세소송은 세금과 관련하여 국세청, 세무서 등 기관에서 일어나는 환급, 물납, 징수에 대해 구제를 하기 위한 사법절차로, 행정/민사/헌법 소송 등 조세와 관련된 소송을 통칭합니다.</span><br/>
						<span>조세소송에는 취소 소송, 무효 등 확인 청구 소송, 조세 환급금 소송, 국가배상 청구소송 등이 있습니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q2. 조세소송까지 생각하고 있는데, 앞선 불복 결과가 많은 영향을 미치나요?</span>
					</div>
					<div class="a">
						<span>이미 결정된 세금에 대한 처분을 뒤집기란 매우 어렵기 때문에, 가급적 소송을 진행하기 전단계인 조세 불복절차에서부터 유리한 결과를 만들어 내는 것이 중요합니다.</span>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q3. 조세소송을 진행하는 것이 맞는지 고민입니다.</span>
					</div>
					<div class="a">
						<span>조세소송은 국가기관을 상대로 하기 때문에 승소율이 매우 낮은 소송으로 손꼽힙니다.</span><br/>
						<span>하지만 과세관청의 오류나 실수가 명백하여 납세자가 억울하게 세금을 부담한 것을 입증할 수 있다면 최후의 수단인 조세소송을 통해 이를 바로 잡으시길 추천드립니다.</span><br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
</style>

<script>
	export default {
		name: 'WorkPart4',
	}
</script>