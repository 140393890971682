<template>
	<div class="main">
		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="background">
					</div>
					<div class="contents">
						<div class="title1">
							<span>잠깐, 변호사를 잘 만나서 <span style="color: #E38787">이런 결과</span>가 나올 수 있었을까요?</span><br/>
							<span>가장 좋은 결과는 굳은 의지와 결단력을 가진 의뢰인께서 가져가셨습니다.</span><br/>
						</div>
						<div class="title2">
							<span>변호사는 뛰어난 선장을 보좌하는 지혜로운 항해사일뿐입니다.</span><br/>
							<span>멀고 험한 길에서도 힘차게 나아가실 수 있도록 의뢰인과 함께합니다.</span><br/>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			background-size: cover;
		}
		.title1 {
			margin-bottom: 30px;
			color: #FFF;
			font-size: 16px;
			font-weight: 700;
			line-height: 30px;
		}
		.title2 {
			color: #FFF;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.container {
			position: relative;
			width: 100%;
			padding: 25px;
			background-size: cover;
			background-position: -5px 0px;
			background-image: url('../assets/how.jpg');
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			position: relative;
			max-width: 1200px;
			margin: auto;
			padding: 100px 0;
		}
		.contents {
			position: absolute;
			top: 25%;
			left: 8%;
			z-index: 1;
		}
		.container {
			position: relative;
			width: 100%;
			height: 23vw;
			background-size: cover;
			background-image: url('../assets/how.jpg');
		}
		.title1 {
			margin-bottom: 30px;
			color: #FFF;
			font-size: 20px;
			font-weight: 700;
			line-height: 36px;
		}
		.title2 {
			color: #FFF;
			font-size: 20px;
			line-height: 36px;
		}
	}
</style>

<script>
	export default {
		name: 'HowResult',
	}
</script>
