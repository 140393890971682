<template>
	<div>
		<div class="header">
			<span>계약서/약관 검토 및 작성</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>“급한 마음에 일단 도장을 찍었는데, 불안해서 계약서 검토를 받고 싶습니다.”</span><br/>
				<span>“저한테 유리한 방향으로 계약서를 다시 작성할 수 있을까요?”</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>전문 변호사를 통한 계약서 검토 및 작성의 필요성</span>
				</div>

				<div class="text">
					<span>"혹시 계약서 검토 비용이 막연히 아깝게 느껴지시나요?"</span><br/>
					<span>저는 의뢰인이 다른 변호사를 통해 작성한 계약서 초안에서 놓칠 뻔한 킬러 조항을 겨우 발견하여 심장이 철렁했던 적도 있고, 제가 마지막으로 추가한 계약 조항 때문에 의뢰인의 14억 원가량 손실을 막아드린 경험도 있습니다.</span><br/>
					<span>이처럼 누구든지 계약서를 소홀하게 생각하면 결국엔 원치 않는 분쟁에 휘말릴 수 있습니다.</span><br/>
					<span style="font-weight: 700">따라서 회사와 사업을 지킬 수 있는 맞춤형 계약서가 있어야 합니다.</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>검토 및 작성 가능한 계약서 종류 및 명칭</span>
				</div>

				<div class="text">
					<span>- 물건 거래 계약서 (납품계약서, 구매계약서, 중장비 대여계약서, 수입상품 거래계약서 등)</span><br/>
					<span>- 지식 거래 계약서 (특허권 양도계약서, 상표권 사용계약서, 음악저작권 사용계약서, 콘텐츠 제휴 계약서 등)</span><br/>
					<span>- 금전 거래 계약서 (현금차용계약서, 주식양수도계약서, 현금 증여계약서, 채무이행계약서 등)</span><br/>
					<span>- 부동산 거래 계약서 (부동산 매매계약서, 전세계약서, 월세계약서, 분양계약서 등)</span><br/>
					<span>- 근로계약서 (계약직, 일용직, 단기간근로자, 프리랜서, 연봉계약서 등)</span><br/>
					<span>- 건설공사 계약서 (공사계약서, 하도급계약서, 설계용역계약서, 인테리어계약서 등)</span><br/>
					<span>- 기타 계약서</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>아래 내용을 검토해드립니다.</span>
				</div>

				<div class="text">
					<span>- 정의 조항</span><br/>
					<span>- 특허, 상표권, 지식재산권의 대상물 범위에 관한 조항</span><br/>
					<span>- 제세 등 양수도 비용부담에 관한 조항</span><br/>
					<span>- 사용 기간</span><br/>
					<span>- 사용가능행위 및 금지행위의 범위</span><br/>
					<span>- 사용료 조항</span><br/>
					<span>- 계약 위반 시 제재 조항</span><br/>
					<span>- 손해배상 조항</span><br/>
					<span>- 관련법 위반 등 규제에 관한 사항</span><br/>
					<span style="font-weight: 700">계약서의 독소조항 등 위험 요소를 모두 제거하여 분쟁을 사전에 방지하고, 계약의 효력과 협상력을 높여주는 계약서를 목표로 검토 및 작성이 진행됩니다.</span><br/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
</style>

<script>
	export default {
		name: 'WorkPart6',
	}
</script>