var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"contents"},[_vm._m(1),_vm._m(2),_c('div',[_vm._m(3),_vm._m(4),_c('div',{staticClass:"question"},[_vm._m(5),_c('div',{staticClass:"a"},[_c('span',[_vm._v("횡령 혐의의 경우 결국 경제범죄이기 때문에 횡령한 금액에 따라 처벌수위가 달라지게 됩니다.")]),_c('br'),_c('v-img',{attrs:{"src":require("@/assets/table.png")}}),_c('span',[_vm._v("특히 업무상 횡령 혐의는 자신이 근무하는 회사의 자금에 접근이 가능한 지위를 이용해 범죄 행위를 저지른 것이기 때문에 보통 횡령죄보다 당연히 처벌수위가 높아집니다.")]),_c('br')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('span',[_vm._v("조세형사")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat"},[_c('span',[_vm._v("“탈세 형량이 궁금합니다..“")]),_c('br'),_c('span',[_vm._v("“허위세금계산서 발급으로 조사가 나왔는데 어떻게 대응해야 하나요?”")]),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"subject"},[_c('span',[_vm._v("조세형사(조세포탈)의 핵심?")])]),_c('div',{staticClass:"text"},[_c('span',[_vm._v("조세형사(조세포탈)의 핵심은 거래관계 구성입니다. 조세형사사건은 일반형사와는 달리 국세청에 전속적 고발건이 있기 때문에 세법에 부합하도록 거래관계를 재조명할 수 있어야 합니다. 또한, 조세형사사건은 조세행정소송과도 맞물려 진행될 수 밖에 없으므로 조세전문변호사와의 대응은 선택이 아닌 필수입니다.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subject"},[_c('span',[_vm._v("조세형사(조세포탈)에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question"},[_c('div',{staticClass:"q"},[_c('span',[_vm._v("Q1. 허위세금계산서 발급, 어떤 처벌을 받게 되나요?")])]),_c('div',{staticClass:"a"},[_c('span',[_vm._v("재화 또는 용역을 공급하거나 받지 않고, 세금계산서를 발급하거나 받는 행위는 조세범처벌법 위반에 해당합니다.")]),_c('br'),_c('span',[_vm._v("이는 3년 이하의 징역 또는 공급가액에 부가가치세율을 적용해 계산 세액의 3배 이하의 상당하는 벌금에 처해질 수 있습니다.")]),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"q"},[_c('span',[_vm._v("Q2. 업무상 횡령, 형량이 궁금합니다.")])])
}]

export { render, staticRenderFns }