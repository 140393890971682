<template>
	<div class="main">
		<v-container fill-height>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>지난 11년간,</span><br/>
							<span>누적 601억원의 <span style="color: #ce4343">의뢰인의 재산</span>을 보호해 드렸습니다.</span><br/>
						</div>
						<div class="title2">
							<span>가족, 회사, 사업을 지키는 법률 자문을 해드립니다.</span><br/>
							<span>허진영 변호사의 승소 전략과 노하우를 직접 확인해 보세요.</span><br/>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			height: 80vw;
			background-size: cover;
			background-image: url('../assets/s1bg.jpg');
		}
		.title1 {
			margin-bottom: 20px;
			color: #000;
			font-size: 18px;
			font-weight: 700;
			line-height: 32px;
		}
		.title2 {
			color: #000;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
	}

	// window
	@media (min-width: 768px) {
		.main {
			width: 100vw;
			height: 36vw;
			background-size: cover;
			background-image: url('../assets/s1bg.jpg');
		}
		.title1 {
			margin-bottom: 40px;
			color: #000;
			font-size: 28px;
			font-weight: 700;
			line-height: 42px;
		}
		.title2 {
			color: #000;
			font-size: 20px;
			line-height: 36px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
	}
</style>

<script>
	export default {
		name: 'HeadLine',
	}
</script>
