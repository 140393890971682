<template>
	<div>
		<div class="header">
			<span>세금컨설팅</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>“부모님이 보유 중이신 아파트를 취득하고 싶은데, 절세할 수 있는 방법이 있을까요?”</span><br/>
				<span>“세대분리된 성인 자녀가 아파트를 구매할 경우 취득세는 얼마나 나오나요?”</span><br/>
				<span>“양도세 이월과세가 적용되면 기존의 증여세와 취득세 모두 비용으로 인정되나요?”</span><br/>
				<span>“1세대 1주택 비과세를 적용하여 양도세를 신고하였는데, 추가 납세고지서를 받아 너무 억울합니다.”</span><br/>
				<span>“혹시 취득세 감면 혜택이 있다면 어떤 방법이 있을까요?”</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>세금컨설팅 200% 활용하는 방법</span>
				</div>

				<div class="text">
					<span>“지금 당장 눈앞의 세금을 줄이면 절세에 성공했다고 볼 수 있을까요?”</span><br/>
					<span>단순히 세금의 '액수'을 줄이기 위한 절세 전략으로는 세금추징, 세무조사 등과 같은 갑작스런 상황에 대응하기 어렵습니다.</span><br/>
					<span>어쩌면 나에게도 일어날 수 있는 세금추징과 세무조사까지 사전에 대비하는 제대로 된 절세를 하기 위해서는 법령, 법리, 판례, 관련 규정 등을 빈틈없이 검토해야 합니다.</span><br/>
					<span>나중에 문제가 되지는 않을까 왠지 모르게 불안하고 찜찜한 부분이 있으신가요?</span><br/>
					<span>그런 걱정은 저에게 넘기시고 더 중요한 것에 고객님의 시간과 노력을 투자하세요.</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>세금컨설팅 케이스</span>
				</div>

				<div class="text">
					<span>1. 토지 수용 보상</span><br/>
					<span>2. 매수자부담 분양권양도</span><br/>
					<span>3. 공유물분할 소송으로 인한 토지양도</span><br/>
					<span>4. 다가구주택 비과세 양도</span><br/>
					<span>5. 아파트 일시적 2주택 비과세 양도</span><br/>
					<span>6. 다주택자 중과 배제 양도</span><br/>
					<span>7. 상속주택으로 인한 다주택자 양도</span><br/>
					<span>8. 분양권취득으로 인한 주택양도</span><br/>
					<span>9. 상속취득으로 인한 양도</span><br/>
					<span>10. 비과세 요건을 충족시키지 못한 주택의 양도</span><br/>
					<span>11. 증여 후 이월과세 양도</span><br/>
					<span>12. 분양권 + 주택 + 입주권 보유자의 양도</span><br/>
					<span>13. 가족 간 저가양도</span><br/>
					<span>14. 혼인 후 다주택자의 양도</span><br/>
					<span>15. 농어촌주택 + 조정대상지역 주택 양도</span><br/>
					<span>16. 조특법 감면 - 자경농지 양도</span><br/>
					<span>17. 조특법 감면- 신축주택 양도</span><br/>
					<span>18. 조특법 감면- 미분양주택 감면</span><br/>
					<span>19. 임대사업자의 거주주택 비과세 양도</span><br/>
					<span>20. 임대사업자의 임대주택 양도</span><br/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
</style>

<script>
	export default {
		name: 'WorkPart6',
	}
</script>