<template>
	<div>
		<div class="header">
			<span>조세불복</span>
		</div>

		<div class="contents">
			<div class="chat">
				<span>"혹시 내지 않아도 되는 세금을 내는 건 아니겠죠?"</span><br/>
				<span>"생각보다 세금이 너무 많이 나왔는데.. 어떡하죠?"</span><br/>
			</div>

			<div>
				<div class="subject">
					<span>조세불복의 핵심?</span>
				</div>

				<div class="text">
					<span>불복은 청구한다고 100% 인용되는 것이 아니기에 부당함을 증명할 수 있는 정확한 법리와 증거가 핵심입니다. 그러므로 섣불리 신청하기보다는 조세법률주의에 따른 법리를 먼저 검토한 후, 그에 맞는 증거와 소명자료가 있는지 확인하여야 합니다.</span>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>조세불복 대응 이런 분들이 필요합니다.</span>
				</div>

				<div class="text">
					<span>· 세무서의 과세논리에 수긍하기 어려운 분</span><br/>
					<span>· 과세예고 통지를 받고 불복을 하고 싶으신 분</span><br/>
					<span>· 납세고지서를 받고 이의신청을 진행하고 싶으신 분</span><br/>
					<span>· 납세고지서를 받고 심사/심판청구, 감사원 심사청구를 진행하고 싶으신 분</span><br/>
					<span>· 각종 세금의 고지세액에 대해 이견이 있어 불복절차를 진행하고 싶으신 분</span><br/>
				</div>
			</div>

			<div>
				<div class="subject">
					<span>조세불복에 대한 모든 것, 조세전문변호사가 직접 답해드립니다.</span>
				</div>

				<div class="question">
					<div class="q">
						<span>Q1. 조세불복하여야 할 사안이 궁금합니다.</span>
					</div>
					<div class="a">
						<span>1. 납득할 수 없는 과세논리로 부당한 세금 납부 요구</span><br/>
						<span>2. 불합리하게 과다한 세금 징수 처분</span><br/>
						<span>3. 정당하게 경정 또는 환급 신청을 했음에도 환급을 못 받은 경우</span><br/>
						<span>4. 감면 신청을 했음에도 감면을 못 받은 경우</span><br/>
						<span>만약 위와 같은 사안이라면 조세전문변호사로부터 상담을 받으시길 바랍니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q2. 조세불복 절차에는 어떤것이 있나요?</span>
					</div>
					<div class="a">
						<span>조세불복 절차는 사전적 구제 제도와 사후적 구제 제도로 나눌 수 있습니다.</span><br/>
						<span>· 사전적 구제 제도: 과세 전 적부심사 청구</span><br/>
						<span>· 사후적 구제 제도: 이의신청, 심사청구, 심판청구, 감사원 심사청구</span><br/>
						<span>이와 같은 사전, 사후적 구제 절차에서도 조세전문변호사의 조력을 받으시길 바랍니다.</span><br/>
					</div>
				</div>

				<div class="question">
					<div class="q">
						<span>Q3. 조세불복 청구기한이 궁금합니다.</span>
					</div>
					<div class="a">
						<span>1. 과세 전 적부 심사는 과세예고 통지서 수령 후 30일 내에 청구할 수 있습니다.</span><br/>
						<span>2. 이의신청, 심사청구, 심판청구는 납세고지서 등을 받은 날(이의신청을 거친 경우에는 이의신청 결정서를 받은 날)로부터 90일 이내에 이의신청서 또는 청구서를 제출해야 합니다.</span><br/>
						<span>이와 같은 기한은 불변기한으로서 반드시 준수하셔야 하겠습니다.</span><br/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	// mobile
	@media (min-width: 0px) and (max-width: 767px) {
		.header {
			margin-bottom: 40px;
			font-size: 20px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 16px;
				font-weight: 700;
			}
			.text {
				font-size: 14px;
				line-height: 24px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 14px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}

	// window
	@media (min-width: 768px) {
		.header {
			margin-bottom: 40px;
			font-size: 32px;
			font-weight: 700;
			text-align: center;
		}
		.contents > div {
			margin-bottom: 40px;
		}
		.contents {
			.chat {
				color: #666;
			}
			.subject {
				margin-bottom: 10px;
				font-size: 20px;
				font-weight: 700;
			}
			.text {
				line-height: 28px;
			}
			.question {
				margin-bottom: 20px;
			}
			.q {
				margin-bottom: 10px;
				font-size: 18px;
				font-weight: 700;
				text-indent: 10px;
			}
			.a {
				padding-left: 20px;
			}
		}
	}
	
</style>

<script>
	export default {
		name: 'WorkPart1',
	}
</script>