<template>
	<div class="main">
		<v-container fill-height>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>YOONSUNG LAWFIRM</span>
						</div>
						<div class="title2">
							<span>서울시 강남구 선릉로 428, 법무법인 윤성</span>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	@font-face {
    font-family: 'Hahmlet-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2110@1.0/Hahmlet-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
	}
	.main {
		width: 100vw;
		padding: 40px 0;
		background: #222;
	}
	.title1 {
		color: #FFF;
		font-size: 20px;
		font-weight: 700;
		font-family: 'Hahmlet-Regular';
		text-align: center;
	}
	.title2 {
		color: #999;
		font-size: 14px;
		font-family: 'Hahmlet-Regular';
		text-align: center;
		line-height: 36px;
	}
	.contents {
		max-width: 1200px;
		margin: auto;
	}
</style>

<script>
	export default {
		name: 'HeadLine',
	}
</script>
