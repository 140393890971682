<template>
	<div class="main">
		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12">
					<div class="contents">
						<div class="title1">
							<span>법무법인 윤성의 약속</span>
						</div>
						<div class="title2">
							<span>법무법인 윤성을 믿고 찾아주시는 분들께 약속드립니다.</span>
						</div>
						<div class="cases">
							<div>
								<div>
									<div class="circle">
										<v-icon size="64" color="white">
											mdi-handshake
										</v-icon>
									</div>
								</div>
								<div>
									<span>1:1 상담 및 소송 수행</span>
								</div>
								<div>
									<span>지난 11년간 사무장을 따로 두지 않고,</span><br/>
									<span>모든 상담을 직접 진행해왔습니다.</span><br/>
									<span>법무법인 윤성은 의뢰인과의 신뢰를 위해</span><br/>
									<span>대표변호사인 허진영 변호사가 상담부터 소송까지</span><br/>
									<span>직접 수행합니다.</span><br/>
								</div>
							</div>
							
							<div>
								<div>
									<div class="circle">
										<v-icon size="64" color="white">
											mdi-numeric-3
										</v-icon>
									</div>
								</div>
								<div>
									<span>높은 퀄리티를 위해 상담 수 제한</span>
								</div>
								<div>
									<span>한 분 한 분 꼼꼼한 상담과 의뢰인의 문제해결에</span><br/>
									<span>집중하기 위해 하루 상담 건수를</span><br/>
									<span>3건으로 제한하고 있습니다.</span><br/>
									<span>모든 의뢰인분들께 양질의 상담을 해드리기 위해</span><br/>
									<span>집중하겠습니다.</span><br/>
								</div>
							</div>
							
							<div>
								<div>
									<div class="circle">
										<v-icon size="64" color="white">
											mdi-alert-outline
										</v-icon>
									</div>
								</div>
								<div>
									<span>불필요한 소송행위 지양</span>
								</div>
								<div>
									<span>의뢰인께 실익이 없는, 즉 승률이 낮은 소송은</span><br/>
									<span>권하지 않기 때문에 무조건 소송을 생각하고 오시는</span><br/>
									<span>고객님은 원하는 답변을 듣지 못하실 수도 있습니다.</span><br/>
									<span>사전 상담을 통해 꼭 변호사 선임이 필요한 분에게만</span><br/>
									<span>선임절차를 안내드립니다.</span><br/>
									<span>기존 의뢰인분들의 소송에 집중하기 위함이니</span><br/>
									<span>넓은 양해 부탁드립니다.</span><br/>
								</div>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<style lang="scss" scoped>
	@media (min-width: 0px) and (max-width: 767px) {
		.main {
			width: 100vw;
			padding: 30px 0;
			background: #F3F3F3;
		}
		.title1 {
			color: #000;
			font-size: 18px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 20px;
			color: #000;
			font-size: 14px;
			line-height: 24px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			width: 100%;
			gap: 15px;
			margin-bottom: 15px;
		}
		.cases > div {
			width: 100%;
			padding: 30px 0;
			margin-bottom: 10px;
			border-radius: 5px;
			border: 1px solid #D9D9D9;
			background: #FFF;
		}
		.cases > div > div:nth-child(1) {
			margin-bottom: 30px;
			text-align: center;

			.circle {
				width: 100px;
				height: 100px;
				padding: 18px;
				margin: auto;
				border-radius: 100%;
				background: #E38787;
			}
		}
		.cases > div > div:nth-child(2) {
			margin-bottom: 30px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}
		.cases > div > div:nth-child(3) {
			font-size: 14px;
			line-height: 24px;
			text-align: center;
		}
	}

	@media (min-width: 768px) {
		.main {
			padding: 100px 0;
			background: #f3f3f3;
		}
		.title1 {
			margin-bottom: 10px;
			font-size: 36px;
			font-weight: 700;
		}
		.title2 {
			margin-bottom: 30px;
			color: #333;
			font-size: 20px;
		}
		.contents {
			max-width: 1200px;
			margin: auto;
		}
		.cases {
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 15px;
			margin-bottom: 15px;
		}
		.cases > div {
			width: 100%;
			padding: 30px 0;
			border-radius: 5px;
			background: #FFF;
		}
		.cases > div > div:nth-child(1) {
			margin-bottom: 30px;
			text-align: center;

			.circle {
				width: 100px;
				height: 100px;
				padding: 18px;
				margin: auto;
				border-radius: 100%;
				background: #E38787;
			}
		}
		.cases > div > div:nth-child(2) {
			margin-bottom: 30px;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
		}
		.cases > div > div:nth-child(3) {
			font-size: 14px;
			line-height: 24px;
			text-align: center;
		}
	}
</style>

<script>
	export default {
		name: 'YsPromise',
	}
</script>
